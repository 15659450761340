import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import './App.css';
import cash from './cash.png';
import coins from './coins.png';
import ticket from './game/ticket.png';
import BottomNav from './BottomNav';
import { SoundWaveLoader } from './SoundWaveLoader';

const slides = [
  { image: '/new/softiead2.png', link: '/softie' },
  { image: '/new/contestad2.png', link: '/contest' },
  { image: '/new/telegramad.png', link: 'https://t.me/softcoinupdates' },
];

export default function Home() {
  const [activeItem, setActiveItem] = useState('home');
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [coinBalance, setCoinBalance] = useState(0);
  const [earningBalance, setEarningBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [miningSessionCount, setMiningSessionCount] = useState(0);
  const [spinTickets, setSpinTickets] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [timer, setTimer] = useState("00:00:00");
  const [timerInterval, setTimerInterval] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [greeting, setGreeting] = useState('Hello');
  const [commitmentBalance, setCommitmentBalance] = useState(null);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [softieLevel, setSoftieLevel] = useState('');
  const [error, setError] = useState('');
  const [dynamicCoinCount, setDynamicCoinCount] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');  // Add walletAddress state
  const [showMiningAlert, setShowMiningAlert] = useState(false); // For controlling modal visibility
  const [miningInProgress, setMiningInProgress] = useState(false); // Add mining status state
  const navigate = useNavigate();
  
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const intervalRef = useRef(null);

  const slideCount = slides.length;

  const startDragging = (position) => {
    setIsDragging(true);
    setStartPosition(position);
    clearInterval(intervalRef.current); // Pause automatic slide
  };

  const stopDragging = () => {
    setIsDragging(false);
    if (translateX > 50) {
      // Slide back
      prevSlide();
    } else if (translateX < -50) {
      // Slide forward
      nextSlide();
    } else {
      // Reset position
      setTranslateX(0);
    }
    autoSlide(); // Resume automatic slide
  };

  const handleDragging = (position) => {
    if (isDragging) {
      setTranslateX(position - startPosition);
    }
  };

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slideCount);
    setTranslateX(0); // Reset after slide
  }, [slideCount]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + slideCount) % slideCount);
    setTranslateX(0); // Reset after slide
  }, [slideCount]);

  const autoSlide = useCallback(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 10000); // Change every 10 seconds
  }, [nextSlide]);

  useEffect(() => {
    autoSlide();
    return () => clearInterval(intervalRef.current); // Clean up on unmount
  }, [autoSlide]);
  
  const handleSlideClick = () => {
    const slide = slides[currentSlide];
    if (slide.link.startsWith('http')) {
      // External link, open in a new tab
      window.open(slide.link, '_blank', 'noopener,noreferrer');
    } else {
      // Internal link, navigate within the app
      navigate(slide.link);
    }
  };

  
  const profilePage = () => {
    navigate('/profile'); // Navigate back to the /softie page
  };

const notificationPage = () => {
    navigate('/notification'); // Navigate back to the /softie page
  };

const upgradePage = () => {
    navigate('/upgrade'); // Navigate back to the /softie page
  };

const withdrawPage = () => {
    navigate('/withdraw'); // Navigate back to the /softie page
  };


  const rewardIntervals = useMemo(() => [2 * 60 * 60 * 1000, 3 * 60 * 60 * 1000, 4 * 60 * 60 * 1000, 5 * 60 * 60 * 1000, 6 * 60 * 60 * 1000], []);
  
  const rewardIntervalsInHours = useMemo(() => [2, 3, 4, 5, 6], []);
  
  const rewards = useMemo(() => [5000, 10000, 20000, 40000, 80000], []);
  
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  
  const handleClick = () => {
    if (!walletAddress) {
      navigate('/connect-wallet');
    }
  };
  
  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#041f4c');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

const fetchWalletAddress = async (username) => {
  try {
    // Corrected the URL by removing the colon
    const response = await fetch(`https://softcoin-axlm.onrender.com/api/walletAddress/${username}`);
    const data = await response.json();

    if (data.walletAddress) {
      // Corrected the variable name to `data.walletAddress`
      setWalletAddress(data.walletAddress);  // Set the wallet address
    } else {
      setWalletAddress('');  // If wallet address is not found, clear the wallet address
    }
  } catch (error) {
    console.error('Error fetching wallet address:', error);
  }
};

const checkMiningStatus = async (username) => {
    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/miningStatus/${username}`);
      const data = await response.json();

      if (data.isMining) {
        setMiningInProgress(true);
      } else {
        setMiningInProgress(false);
      }
    } catch (error) {
      console.error('Error fetching mining status:', error);
    } finally {
      setLoading(false); // Set loading to false after status is determined
    }
  };
  
  useEffect(() => {
    const fetchGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour < 18) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    };

    fetchGreeting();
  }, []);
  
  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/notifications/${username}`);
        let notificationsData = await response.json();

        // Sort notifications by date (most recent first)
        notificationsData.sort((a, b) => new Date(b.date) - new Date(a.date));

        let unread = 0;
        notificationsData.forEach(notification => {
          if (!notification.read) unread++;
        });

        setNotifications(notificationsData);
        setUnreadCount(unread);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }

    fetchNotifications();
  }, [username]);
  
  useEffect(() => {
    if (username) {
      const fetchEarningBalance = async () => {
        try {
          const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/earningBalance`);
          if (!response.ok) {
            throw new Error('Failed to fetch earning balance');
          }
          const data = await response.json();
          setEarningBalance(data.earningBalance.toLocaleString(undefined, { maximumFractionDigits: 2 }));
        } catch (error) {
          console.error('Error fetching earning balance:', error);
        }
      };

      fetchEarningBalance();
    }
  }, [username]);
  
  useEffect(() => {
        fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/commitmentBalance`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError('Error fetching commitment balance');
                } else {
                    const balance = data.commitmentBalance;
                    setCommitmentBalance(balance);

                    let profit = 0;
                    let level = '';

                    if (balance >= 5 && balance <= 30) {
                        profit = balance * 0.015;
                        level = 'Rising Softie';
                    } else if (balance >= 31 && balance <= 100) {
                        profit = balance * 0.02;
                        level = 'Junior Softie';
                    } else if (balance >= 101 && balance <= 500) {
                        profit = balance * 0.025;
                        level = 'Pro Softie';
                    } else if (balance >= 501 && balance <= 2000) {
                        profit = balance * 0.03;
                        level = 'Expert Softie';
                    } else if (balance >= 2001 && balance <= 5000) {
                        profit = balance * 0.035;
                        level = 'Master Softie';
                    } else if (balance >= 5001) {
                        profit = balance * 0.04;
                        level = 'Legend Softie';
                    }

                    setDailyProfit(profit.toFixed(2));
                    setSoftieLevel(level);
                }
            })
            .catch(() => {
                setError('Error fetching commitment balance');
            });
    }, [username]);

  const startMining = async () => {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/startMiningMini', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
      });
      const data = await response.json();
      if (data.miningStartTime) {
        startTimer(new Date(data.miningStartTime).getTime(), data.level);
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setShowMiningAlert(true); // Show alert modal when mining starts
      } else {
        console.error(data.message || "Error starting mining");
      }
    } catch (error) {
      console.error("Failed to start mining", error);
    }
  };

  const handleSpinNow = () => {
    setShowMiningAlert(false);
    navigate('/wheel-of-fortune'); // Navigate to the spinning wheel
  };

  const handleLater = () => {
    setShowMiningAlert(false); // Close the alert
  };

  const startTimer = useCallback((startTime, level) => {
  const endTime = startTime + rewardIntervals[level - 1];
  const totalReward = rewards[level - 1];

  const updateTimer = () => {
    const remainingTime = endTime - Date.now();
    if (remainingTime <= 0) {
      clearInterval(timerInterval);
      setTimer("00:00:00");
      setStatusMessage("Mining complete!");
      setCoinBalance(prevBalance => prevBalance + totalReward); // Update the static balance after mining is done
      setDynamicCoinCount(0); // Reset the dynamic count
      setMiningSessionCount(prevCount => prevCount + 1);
    } else {
      const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
      const seconds = Math.floor((remainingTime / 1000) % 60);
      setTimer(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);

      // Calculate the newly mined coins so far
      const elapsedTime = Date.now() - startTime;
      const minedCoins = totalReward * (elapsedTime / rewardIntervals[level - 1]);

      // Update only the dynamic coin count
      setDynamicCoinCount(minedCoins);
    }
  };

  updateTimer();
  const interval = setInterval(updateTimer, 1000); // Update every second
  setTimerInterval(interval);
}, [timerInterval, rewards, rewardIntervals]);

const updateMiningStatus = useCallback(async (username) => {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/miningStatus', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
      });
      const data = await response.json();
      if (data.miningComplete) {
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setStatusMessage("Mining complete!");
      } else if (data.miningStartTime) {
        startTimer(new Date(data.miningStartTime).getTime(), data.level);
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setStatusMessage("Mining in progress...");
      } else {
        setStatusMessage("Mining not started");
      }
    } catch (error) {
      setStatusMessage("Mining in progress...");
    }
  }, [startTimer]);

useEffect(() => {
  const fetchTelegramUser = () => {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const telegramUserData = window.Telegram.WebApp.initDataUnsafe.user;
        
        if (telegramUserData.username) {
          setUsername(telegramUserData.username); // Keep the existing username logic
          setFirstName(telegramUserData.first_name || telegramUserData.username); // Use first name if available
          updateMiningStatus(telegramUserData.username);
          fetchWalletAddress(telegramUserData.username);
          checkMiningStatus(telegramUserData.username);
        } else if (telegramUserData.id) { 
          setUsername(telegramUserData.id.toString());
          setFirstName(telegramUserData.first_name || telegramUserData.id.toString()); // Use first name or fallback to id
          updateMiningStatus(telegramUserData.id.toString());
          fetchWalletAddress(telegramUserData.id.toString());
          checkMiningStatus(telegramUserData.id.toString());
        } else {
          console.error('No username or chatId available from Telegram.');
        }
      } else {
        console.error('Telegram WebApp is not initialized.');
      }
    } catch (error) {
      console.error('Error fetching user data from Telegram:', error);
    }
  };

  fetchTelegramUser();
}, [updateMiningStatus, fetchWalletAddress, checkMiningStatus]);

  return (
    <div className="app">
      <header className="top-bar">
        <div className="greeting">
        
          <p style={{ textAlign: 'left', marginBottom: '', fontSize: '8px', color: '#48e4fa' }}>{greeting},<br/><span style={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: 'white' }}>{firstName}!</span></p>
        </div>
        <div className="icons">
          <a onClick={upgradePage} className="icon-container">
            <i className="fas fa-bolt"></i>
          </a>
          <a onClick={profilePage} className="icon-container">
            <i style={{ color: '#48e4fa' }} className="fas fa-user-circle"></i>
          </a>
          <a onClick={notificationPage} className="icon-container bell">
            <i className="fas fa-bell"></i>
             {unreadCount > 0 && (
              <span className="icon-badge">{unreadCount}</span>
            )}
          </a>

        </div>
      </header>
      
      <div className="app-body">
    <div className="slideshow">
      <div
        className="slide-container"
        style={{
          transform: `translateX(calc(-${currentSlide * 100}% + ${translateX}px))`,
          transition: isDragging ? 'none' : 'transform 0.5s ease-in-out',
        }}
        onMouseDown={(e) => startDragging(e.clientX)}
        onMouseMove={(e) => handleDragging(e.clientX)}
        onMouseUp={stopDragging}
        onTouchStart={(e) => startDragging(e.touches[0].clientX)}
        onTouchMove={(e) => handleDragging(e.touches[0].clientX)}
        onTouchEnd={stopDragging}
      >
        
        
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <div style={{ margin: '0 auto', width: '92%'}} onClick={handleSlideClick}>
              <img src={slide.image} alt={`Slide ${index + 1}`} />
            </div>
          </div>
        ))}
      </div>

    </div>

      <div className="vector">
        <div className="balance-box">
        <div className="balance-item">
          <img src="/menu/home-neon.png" alt="Coin Logo" className="balance-logo coin-logo" />
          <span className="balance-text coin">{coinBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>SFT</span></span>
        </div>
        <div className="balance-item cash">
          <img style={{ marginLeft: '10px'}} src="/menu/softie-neon.png" alt="Earning Logo" className="balance-logo" />
          <span className="balance-text">{earningBalance !== null ? earningBalance : "Loading..."}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>USD</span></span>
        </div>
      </div>
      <div className="wallet">
     <button 
      className="connect" 
      onClick={handleClick} 
      disabled={!!walletAddress}  // Disable if walletAddress is not empty
    >
      {walletAddress ? '🛡 Wallet Connected️!' : 'Connect Wallet 🚀'}  
    </button>
        
        <a onClick={withdrawPage} className="cashout"><span>Withdraw</span>
        <i className="fas fa-chevron-right" style={{ color: '#041f4c', marginLeft: '50px' }}></i>
        </a>
      </div>
      </div>

      {/* Coin Balance */}
      <div className="coin-balance">
    <strong>
      <p id="coin-balance" style={{ marginLeft: '0px' }} >+ {dynamicCoinCount.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
    </strong>
  </div> 
  <div className="status-message"><p id="status-message">{statusMessage}</p></div>

{loading ? (
      <div style={{ marginLeft: '10px', padding: '60px'}}>
        <SoundWaveLoader /> 
      </div>
      ) : (
        <>
          {miningInProgress ? (
          <div className="mango">
            <button
  id="mine-btn"
  onClick={startMining}
  disabled={statusMessage === "Mining in progress..."}
  className={statusMessage === "Mining complete!" ? "breathing" : statusMessage === "Mining in progress..." ? "circulate" : ""}
>
  {statusMessage === "Mining in progress..." ? timer : ""}
</button>
</div>
          ) : (
          <div className="mango">
            <button
  id="mine-btn"
  onClick={startMining}
  disabled={statusMessage === "Mining in progress..."}
  className={statusMessage === "Mining complete!" ? "breathing" : statusMessage === "Mining in progress..." ? "circulate" : ""}
>
  {statusMessage === "Mining in progress..." ? timer : ""}
</button>
</div>
          )}
        </>
      )}

<div className="levels">
  <p>Daily Income: <span>${dailyProfit}</span></p>
  <p>Mining Level: <span>{currentLevel}</span></p>
  <p>Sessions Completed: <span>{miningSessionCount}</span></p>
  <div className="ticket-box" ><img className="ticket" src={ticket} alt="ticket"/><p>{spinTickets}</p></div>
    
</div>

<p style={{ display: 'none'}}>{error}</p>
</div>
      {/* Conditional Alert Modal */}
      
      {showMiningAlert && (
      <div className="modal-overlay">
        <div className="mining-alert">
          <div className="alert-content">
            <i style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '50px'}} className="fas fa-check"></i>
            <h2 style={{ fontFamily: 'arial'}}>Mining Started Successfully!</h2>
            <p>Session ends in {rewardIntervalsInHours[currentLevel - 1]} hours.</p>
            <p style={{ color: '#48e4fa', fontWeight: 'bold'}}>Reward: {rewards[currentLevel - 1]} SFT</p>
            <p>You now have <span style={{ color: 'gold', fontWeight: 'bold'}}>{spinTickets}</span> Spin Tickets. Spin the wheel now for more rewards.</p>
            <div className="alert-buttons">
              <button onClick={handleSpinNow}><i className="fas fa-spin"></i>Spin</button>
              <button style={{ background: 'gray'}} onClick={handleLater}>Later</button>
            </div>
          </div>
        </div>
        </div>
      )}
      
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
