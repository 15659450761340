import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './App.css';
import Tasks from './Tasks';
import Friends from './Friends';
import Softie from './Softie';
import More from './More';
import BottomNav from './BottomNav';
import Home from './Home';
import { SoundWaveLoader } from './SoundWaveLoader';
import Upgrade from './Upgrade';
import Notification from './Notification';
import ConnectWallet from './ConnectWallet';
import Withdraw from './Withdraw';
import Deposit from './Deposit';
import SoftieLevels from './SoftieLevels';
import History from './History';
import CryptoMarket from './CryptoMarket';
import WheelOfFortune from './WheelOfFortune';
import Contest from './Contest';
import Profile from './Profile';
import Trybe from './Trybe';

function App() {
  const [user, setUser] = useState(null);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const navigate = useNavigate();
  const location = useLocation();
  
  React.useEffect(() => {
   var _mtm = window._mtm = window._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://cdn.matomo.cloud/softcoinmini.matomo.cloud/container_DMy5zCg1.js'; s.parentNode.insertBefore(g,s);
  }, [])

  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set user data
    const userData = webApp.initDataUnsafe?.user;
    setUser(userData);

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded
    webApp.expand(); // Expand the Telegram browser window

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#041f4c');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  useEffect(() => {
  const timer = setTimeout(() => {
    navigate('/home');
  }, 10000); // 2 seconds delay

  // Cleanup timer on component unmount
  return () => clearTimeout(timer);
}, [navigate]);


  return (
    <div className="App plain-page">
      <div className="logo-container">
        <img
          style={{ width: '250px' }}
          src="transparent-logo2/9.png" // Ensure this path is correct
          alt="Softcoin Logo"
          className="logo"
          onError={() => console.error('Logo failed to load')}
        />
      </div> 
{user ? (
  <div className="loader">
    <p>Welcome,</p>
    <p style={{ fontWeight: 'bold', color: '#fff', fontSize: '30px', marginBottom: '20px' }}>
      {user.first_name}!
    </p>
    <p style={{ fontSize: '11px' }}>
      Telegram ID: <span style={{ color: '#48e4fa' }}>{user.id}</span>
    </p>
  </div>
) : (
  <div className="loader">
    <SoundWaveLoader />
  </div>
)}

<div className="home-btn"> 
  {/* Commented buttons */}
  {/* <button onClick={() => window.Telegram.WebApp.close()} className="submit-button">
    Close Mini App
  </button>  */}
   <SoundWaveLoader />
</div> 
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/softie" element={<Softie />} />
      <Route path="/more" element={<More />} />
      <Route path="/home" element={<Home />} />
      <Route path="/upgrade" element={<Upgrade />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/connect-wallet" element={<ConnectWallet />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/deposit" element={<Deposit />} />
      <Route path="/softie-levels" element={<SoftieLevels />} />
      <Route path="/history" element={<History />} />
      <Route path="/crypto-market" element={<CryptoMarket />} />
      <Route path="/wheel-of-fortune" element={<WheelOfFortune />} />
      <Route path="/contest" element={<Contest />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/trybe" element={<Trybe />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
