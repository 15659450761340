import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ConnectWallet.css'; 
import CustomAlert from './CustomAlert';

export default function ConnectWallet() {
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false); 
  const [alertMessage, setAlertMessage] = useState(null); 
  const [isUpdating, setIsUpdating] = useState(false);  // Track if user is updating the wallet address
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/home'); // Navigate back to the /softie page
  };


  const closeAlert = () => {
    setAlertMessage(null);
  };

  const isValidWalletAddress = (address) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(address);
  };
  
  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#041f4c');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);


  useEffect(() => {
  const fetchUserData = async () => {
    try {
      // Check if Telegram WebApp is available
      if (!window.Telegram || !window.Telegram.WebApp) {
        console.error('Telegram WebApp is not defined');
        return;
      }

      const webApp = window.Telegram.WebApp;
      webApp.ready(); // Ensure the app is fully loaded

      const userData = webApp.initDataUnsafe?.user;
      const telegramUsername = userData?.username;
      const telegramChatId = userData?.id;

      // Use username if available, else fall back to chatId (as string)
      const username = telegramUsername || telegramChatId?.toString();

      if (!username) {
        console.error('Username or Chat ID not available');
        return;
      }

      // Fetch profile data using the username or chatId
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/profile/${username}`);
        const data = await response.json();

        if (data.walletAddress) {
          setIsUpdating(true); // If walletAddress exists, the user is updating it
          setWalletAddress(data.walletAddress); // Pre-fill the wallet address
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    } catch (error) {
      console.error('An error occurred while initializing Telegram WebApp:', error);
    }
  };

  fetchUserData();
}, []);

  const handleConnectWallet = async (e) => {
  e.preventDefault();

  // Validate wallet address
  if (!isValidWalletAddress(walletAddress)) {
    showCustomAlert('Invalid wallet address. Please enter a valid BEP-20 address.');
    return;
  }

  setLoading(true);

  try {
    // Fetch username or chatId from Telegram WebApp
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      showCustomAlert('Telegram WebApp is not available.');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready(); // Ensure the app is fully loaded

    const userData = webApp.initDataUnsafe?.user;
    const telegramUsername = userData?.username;
    const telegramChatId = userData?.id;

    // Use username if available, otherwise fallback to chatId as a string
    const username = telegramUsername || telegramChatId?.toString();

    if (!username) {
      showCustomAlert('Unable to retrieve username or chat ID from Telegram.');
      setLoading(false);
      return;
    }

    // Send the username and wallet address to your backend
    const response = await fetch(`https://softcoin-axlm.onrender.com/api/connect-wallet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, walletAddress }),
    });

    const data = await response.json();
    if (data.success) {
      showCustomAlert(isUpdating ? 'Wallet address updated successfully!' : 'Wallet address connected successfully!');
      setTimeout(() => {
        navigate('/home');
      }, 2000);
    } else {
      showCustomAlert(isUpdating ? 'Failed to update wallet address.' : 'Failed to connect wallet address.');
    }
  } catch (error) {
    console.error('Error connecting wallet:', error);
    showCustomAlert('An error occurred. Please try again.');
  } finally {
    setLoading(false);
  }
};

  const showCustomAlert = (message) => {
    setAlertMessage(message);
  };

  return (
    <div className="connect-page app">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <img src="softies/deposit.png" alt="Connect Wallet"/>
      <h3>{isUpdating ? 'UPDATE YOUR BEP-20 WALLET' : 'CONNECT YOUR BEP-20 WALLET'}</h3>
      <p className="para">
        {isUpdating 
          ? 'You are about to update your existing wallet address. Ensure the new BEP-20 address is correct for easy withdrawal of your earnings and to receive your airdrop.'
          : 'You need to connect your crypto wallet (BEP-20) for easy withdrawal of your earnings, and to receive your airdrop.'
        }
      </p>
      <input
        type="text"
        id="wallet-address"
        placeholder="Paste Your Wallet Address Here"
        value={walletAddress}
        onChange={(e) => setWalletAddress(e.target.value)}
        required
      />
      <button
        style={{ width: '50%' }}
        className="submit-button"
        type="submit"
        onClick={handleConnectWallet}
        disabled={loading}
      >
        {loading ? (isUpdating ? 'Updating...' : 'Connecting...') : (isUpdating ? 'Update' : 'Connect')}
      </button>
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
