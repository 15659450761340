import './PrizeModal.css';

export default function PrizeModal({ prizeImage, onClose }) {
    return (
        <div className="prize-modal">
            <div className="prize-modal-content">
                <img src={prizeImage} alt="Prize" />
                <button className="close-button" onClick={onClose}><i className="fas fa-close"></i></button>
            </div>
        </div>
    );
}
