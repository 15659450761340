import React from 'react';
import './Tasks.css'; // Make sure to include any relevant CSS here

export default function TaskItem({ task, handleButtonClick, loadingTaskId }) {
  return (
    <div key={task.id} className={`task ${task.claimed ? 'claimed' : ''}`}>
      <div id="main-div">
        <img src={task.logo} alt={`${task.name} logo`} className="task-logo" />
        <div id="task-item-div">
          <span>{task.name}</span>
          <p className="reward">+{task.reward.toLocaleString()}<span> SFT</span></p>
        </div>
        <div id="action-div">
          <button
            id={task.id}
            onClick={() => handleButtonClick(task)}
            className={`task-button ${task.claimed ? 'disabled' : task.buttonText === "Claim" ? 'green' : 'faint-green'}`}
            disabled={task.claimed || loadingTaskId === task.id}
          >
            {loadingTaskId === task.id ? (
              <div className="loader2"></div> // Show loader if task is being claimed
            ) : (
              task.buttonText // Otherwise, show the button text
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
