import BottomNav from './BottomNav';
import { useState, useEffect } from 'react';
import './Friends.css';
import CustomAlert from './CustomAlert'; // Import CustomAlert

export default function Friends() {
  const [activeItem, setActiveItem] = useState('friends');
  const [friendsData, setFriendsData] = useState({
    referrals: [],
    totalEarnings: 0,
  });
  const [totalSoftieBonus, setTotalSoftieBonus] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  useEffect(() => {
    try {
      if (!window.Telegram || !window.Telegram.WebApp) {
        console.error('Telegram WebApp is not defined');
        return;
      }
  
      const webApp = window.Telegram.WebApp;
      webApp.ready();  // Ensure the app is fully loaded
  
      // Set the color of the title bar
      webApp.setHeaderColor('#041f4c');
  
    } catch (error) {
      console.error('An error occurred during initialization:', error);
    }
  }, []);  

  useEffect(() => {
    const fetchFriendsData = async () => {
        const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
      
        // Use username if available, otherwise use chatId converted to a string
        const username = telegramUser?.username || String(telegramUser?.id);
  
        if (!username) {
          console.error('No Telegram user data found');
          return;
        }

      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/referrals/${username}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setFriendsData(data);

        let softieBonus = 0;
        data.referrals.forEach(ref => {
          softieBonus += ref.commitmentBalance * 0.05;
        });
        setTotalSoftieBonus(softieBonus.toFixed(2));
      } catch (error) {
        console.error('Error fetching referrals:', error);
      }
    };

    fetchFriendsData();
  }, []);

  const handleInviteClick = () => {
    const tg = window.Telegram.WebApp; // Access Telegram WebApp object
    const username = tg.initDataUnsafe?.user?.username || tg.initDataUnsafe?.user?.id; // Get username or fallback to chatId
  
    const referralLink = `https://t.me/SoftcoinAppBot?start=${username}`; // Use Telegram bot with the referrer username or chatId
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        showCustomAlert('Referral link copied to clipboard!');
      })
      .catch(() => {
        showCustomAlert('Failed to copy referral link.');
      });
  };

const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
};
  return (
    <div className="app">
      <h1 style={{ marginTop: '25px', fontSize: '30px'}}>Friends</h1>
    <div className="friends-page">
      
      <div id="shalaye">
        <p>Invite your friends and get <span style={{ color: 'white'}}>50,000 SFT</span> and <span style={{ color: 'white'}}>20 Spin Tickets</span>. You will also get <span style={{ color: 'white'}}>20%</span> of their mining rewards, <span style={{ color: 'white'}}>5%</span> of their commitment deposit, and <span style={{ color: 'white'}}>10%</span> of their daily return.</p>
        <img className="image" src="/adverts/friends.png" />
      </div>
      <div style={{ width: '50%', margin: '10px auto'}}>
      <button className="friends-button" onClick={handleInviteClick}>
        Copy Referral Link
      </button>
      </div>
      <p id="earning-text" style={{ color: '#ffffff', fontSize: '12px', marginBottom: '0', backgroundColor: '#d7edff1a', width: '70%', borderRadius: '5px', margin: 'auto', marginTop: '5px', fontWeight: 'bold' }}>Referral Earnings</p>
      <div className="referral" style={{ borderBottom: 'solid 0.5px #c0c0c06a', paddingBottom: '10px' }}>
        <div>
          <p>Mining Bonus</p>
          <h3 className="earnings">{friendsData.totalEarnings} SFT</h3>
        </div>
        <div>
          <p>Softie Bonus</p>
          <h3 className="softie-bonus">{totalSoftieBonus} USD</h3>
        </div>
      </div>
      <div className="count">
        <h2 id="friends-count" style={{ marginTop: '5px', color: 'gold' }}>Your Friends:</h2><span style={{ color: 'gold' }}>{friendsData.referrals.length}</span>
      </div>
      <div className="friends-container">
        {friendsData.referrals.length === 0 ? (
          <p style={{ fontSize: '16px', marginTop: '20px'}}>You have not invited any friend yet. Click the button below to copy your invitation link.</p>
        ) : (
          friendsData.referrals.map((ref, index) => (
            <div key={index} className="friend-item">
              <h3>{ref.username}</h3>
              <div className="balances">
                <span>{ref.coinBalance} SFT</span><br />
                <span>{ref.commitmentBalance} USD</span>
              </div>
            </div>
          ))
        )}
      </div>
      
      {/* Custom Alert */}
    {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
    </div>
  );
}


