import { useState, useEffect } from 'react';
import './Softie.css'; 
import './Contest.css';
import { SoundWaveLoader } from './SoundWaveLoader';
import { useNavigate } from 'react-router-dom';

export default function Contest() {
  const [topUsers, setTopUsers] = useState([]);  // Initialize as an empty array
  const [userRank, setUserRank] = useState('--');  // State to hold the current user's rank
  const [currentMonth, setCurrentMonth] = useState(''); // State to hold current month name
  const [daysLeft, setDaysLeft] = useState(0); // State to hold days left in the month
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/more'); // Navigate back to the /softie page
  };

  // Retrieve the logged-in username from local storage
  const loggedInUsername = window.Telegram.WebApp.initDataUnsafe?.user?.username || window.Telegram.WebApp.initDataUnsafe?.user?.id;

  // Fetch the top 20 users and the logged-in user's rank
  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        if (loggedInUsername) {
          const response = await fetch(`https://softcoin-axlm.onrender.com/api/top-referrals?username=${loggedInUsername}`);
          const data = await response.json();
          setTopUsers(data.topUsers || []);  // Ensure topUsers is always an array
          setUserRank(data.userRank || '--');  // Fallback to '--' if userRank is not available
        } else {
          console.error('No logged-in username found in local storage');
        }
      } catch (error) {
        console.error('Error fetching top users:', error);
      }
    };

    fetchTopUsers();
  }, [loggedInUsername]);
  
  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#041f4c');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);


  // Function to calculate current month and days left
  useEffect(() => {
    const getMonthAndDaysLeft = () => {
      const today = new Date();
      const currentMonthName = today.toLocaleString('default', { month: 'long' });  // Get full month name
      const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(); // Get total days in current month
      const remainingDays = daysInMonth - today.getDate();  // Calculate days left
      
      setCurrentMonth(currentMonthName);
      setDaysLeft(remainingDays);
    };

    getMonthAndDaysLeft();  // Call the function when component loads
  }, []);

    // Filter top users to only include those with at least 1 referral
  const filteredTopUsers = topUsers.filter(user => user.referralCount > 0);

  return (
    <div style={{ margin: '0 auto'}} className="leaderboard">
      <h2 style={{ background: 'none', border: 'none', margin: '15px auto', fontSize: '25px'}} >Monthly Referral Contest</h2>
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <div className="wisdom">
      <div className="market-summary">
        <div className="days-left" >
          <div id="month"><i className="fas fa-calendar-days" style={{ color: 'white', marginRight: '5px'}}></i>{currentMonth} <span style={{ color: '#a9a9a9', fontSize: '15px'}}>Edition</span></div>
          <div id="days-left" style={{ fontWeight: 'bold'}}>{daysLeft} <span style={{ color: '#a9a9a9', fontSize: '15px'}}>days to go</span></div>
        </div>
        <p style={{ fontSize: '11px', padding: '5px', borderRadius: '5px', border: 'solid 0.5px #48e4fa'}} id="text">Get yourself to the top 20 by the end of the month, and get a share of the <span style={{fontSize: '10px'}}>$300</span> and <span style={{fontSize: '10px'}}>50 Million SFT</span> up for grab.</p>
        <p style={{ fontSize: '9px', color: '#a9a9a9'}}><span style={{ color: '#fae8ff', fontSize: '9px'}}>Note:</span> Creation of multiple accounts by one person will lead to an outright disqualification and ban.</p>
      </div>
      <div className="user-rank">
        <p>Hello {loggedInUsername}, you are number <span>{userRank}</span> today.</p>
      </div>
      <h3 style={{background: '#ffffffa0', width: '20%', padding: '5px', marginBottom: '20px'}}>Top 20</h3>

      {/* Leaderboard Section */}
      <section style={{ width: '94%'}} className="leaderboard-section">
        <ul className="leaderboard-list">
          {filteredTopUsers.length > 0 ? (
            filteredTopUsers.map((user, index) => {
              const cashPrize = user.prize?.cash || 0;  // Fallback to 0 if 'cash' doesn't exist
              const sftPrize = user.prize?.sft || 0;    // Fallback to 0 if 'sft' doesn't exist

              return (
                <li key={user.username} className="leaderboard-item">
                  <div className="details">
                    <span className="username">{user.username}</span>
                    <span className="commitment">
                      {/* Only display cash prize if it's greater than 0 */}
                      <span style={{ color: 'gold'}}>{cashPrize > 0 && `$${cashPrize}   |`}</span>
                      {sftPrize.toLocaleString()} SFT
                    </span>
                  </div>
                  <p style={{ fontSize: '10px', fontWeight: 'bold'}}>{user.referralCount} <span style={{ color: '#a9a9a9'}}>Referrals</span></p>
                  <div className="rank-indicator">
                    {index === 0 && <img src="game/first.png" alt="1st rank" className="rank-icon" />}
                    {index === 1 && <img src="game/second.png" alt="2nd rank" className="rank-icon" />}
                    {index === 2 && <img src="game/third.png" alt="3rd rank" className="rank-icon" />}
                    {index > 2 && <span>{index + 1}</span>}
                  </div>
                </li>
              );
            })
          ) : (
           <div style={{ marginRight: '-20px'}} >
          <SoundWaveLoader />
        </div>
          )}
        </ul>
      </section>
      </div>
    </div>
  );
}
