import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

export default function Profile() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for form submission
    const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/home'); // Navigate back to the /softie page
  };

    useEffect(() => {
        try {
            if (!window.Telegram || !window.Telegram.WebApp) {
                console.error('Telegram WebApp is not defined');
                return;
            }

            const webApp = window.Telegram.WebApp;
            webApp.ready();  // Ensure the app is fully loaded

            // Set the color of the title bar
            webApp.setHeaderColor('#041f4c');

        } catch (error) {
            console.error('An error occurred during initialization:', error);
        }
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
            const username = telegramUser?.username || String(telegramUser?.id);

            if (!username) {
                console.error('No Telegram user data available');
                return;
            }

            try {
                const response = await fetch(`https://softcoin-axlm.onrender.com/api/profile/${username}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setUser(data);

                // Show email modal if the user has no email on file
                if (!data.email) {
                    setShowEmailModal(true);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChangeWallet = () => {
        navigate('/connect-wallet');
    };

    const handleEmailChange = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set form to submitting state
        try {
            const response = await fetch('https://softcoin-axlm.onrender.com/api/auth/update-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newEmail, username: user.username }),
            });
            const data = await response.json();
            if (response.ok) {
                setEmailMessage('OTP sent to your new email. Please verify to update.');
                setShowEmailModal(false);
                setShowOtpModal(true);
            } else {
                setEmailMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setEmailMessage('Error updating email.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set form to submitting state
        try {
            const response = await fetch('https://softcoin-axlm.onrender.com/api/auth/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ otp, username: user.username }),
            });
            const data = await response.json();
            if (response.ok) {
                setOtpMessage('Email verified and updated successfully!');
                setShowOtpModal(false);
            } else {
                setOtpMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setOtpMessage('There was an error verifying the OTP, please try again.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="profile">
            <a onClick={goBack}>
                <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '9999', fontSize: '20px' }}></i>
            </a>
            <h2 style={{ marginTop: '20px', fontSize: '30px'}}>Profile</h2>
            {user ? (
                <div className="profile-info">
                    <p><strong>Full Name:</strong><span>{user.fullName}</span></p>
                    <p><strong>Username:</strong><span>{user.username}</span></p>
                    <p><strong>Email:</strong><span>{user.email || 'No email on file'}</span></p>
                    <p><strong>Mining Level:</strong><span>Level {user.level}</span></p>
                    <p><strong>Coin Balance:</strong><span style={{color: '#48e4fa', fontWeight: 'bold'}}>{user.coinBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })} SFT</span></p>
                    <p><strong>Earnings:</strong><span style={{color: 'gold', fontWeight: 'bold'}}>${user.earningBalance.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                    <p><strong>Referrals:</strong><span>{user.referralCount}</span></p>
                    <p><strong>No. of Keys Collected:</strong><span>{user.key}</span></p>
                    <p><strong>Trybe Earnings:</strong><span>${user.trybeEarnings.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                    <p><strong>Commitment:</strong><span>${user.commitmentBalance}</span></p>
                    <p style={{fontSize: '12px'}}><strong>Wallet Address:</strong><span>{user.walletAddress || "Not Connected"}</span></p>
                    <p><strong>Total Withdrawal:</strong><span>${user.totalWithdrawal}</span></p>
                </div>
            ) : (
                <p>No user data found.</p>
            )}

            <button 
                style={{ marginTop: '60px' }} 
                onClick={handleChangeWallet} 
                className="submit-button"
            >
                {user.walletAddress ? 'Change Wallet Address' : 'Connect Wallet Address'}
            </button>

            {/* Email Update Modal */}
            {showEmailModal && (
                <div className="modal">
                    
                    <form className="modal-form" onSubmit={handleEmailChange}>
                      <p>You need to connect your email address.</p>
                        <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Processing...' : 'Submit Email'}
                        </button>
                        {emailMessage && <p>{emailMessage}</p>}
                    </form>
                    
                </div>
            )}

            {/* OTP Verification Modal */}
            {showOtpModal && (
                <div className="modal">
                   
                    <form className="modal-form" onSubmit={handleOtpSubmit}>
                      <p>OTP sent to your email. Please verify to update.</p>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                            required
                        />
                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Processing...' : 'Verify OTP'}
                        </button>
                         {otpMessage && <p>{otpMessage}</p>}
                    </form>
                   
                </div>
            )}
        </div>
    );
}
