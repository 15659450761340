// taskUrls.js
export const taskUrls = {
  'followw-btn': 'https://twitter.com/softcoin_pro',
        'pi': 'https://minepi.com/Davidnelson112',
        'paws': 'https://t.me/PAWSOG_bot/PAWS?startapp=5rnJLvCG',
        'doctorx': 'https://doctorx.meme/@davidnelson',
        'facebook': 'https://www.facebook.com/profile.php?id=61567880244086',
        'tiktok': 'https://www.tiktok.com/@softcoinpro',
        'mrbitgenie': 'https://t.me/MrBitGenie_bot/tap?startapp=I45bd05ea',
        'miniapps': 'https://t.me/miniappscenterbot?start=aW52PTY5Nzk2',
        'coub': 'https://t.me/coub/app?startapp=coub__marker_18411358',
        'faucetpay': 'https://faucetpay.io/?r=1413506',
        'binance': 'https://www.binance.com/activity/referral-entry/CPA?ref=CPA_006WFACZKB',
        'tondao': 'https://t.me/tonxdao_bot?start=dao_1170015722_1100147',
        'notpixel': 'https://t.me/notpixel/app?startapp=f1170015722',
        'goat': 'https://t.me/realgoats_bot/run?startapp=fe2dde2f-6ffa-4269-9de2-0a14e587a6ed',
        'tada': 'https://t.me/TADA_Ride_Bot/join?startapp=ref_gCyQ-hIuxn',
        'wallet': 'https://t.me/herewalletbot/grafun?startapp=D9EVkxPxnT0edsXAGPdA1odSz2n',
        'litecoin': 'https://free-litecoin.com/login?referer=1639438',
        'memefix': 'https://t.me/memefi_coin_bot/main?startapp=r_51d911bfe1',
        'memelab': 'https://t.me/MemesLabBot?start=B77UIR',
        'sealsend': 'https://sealsend.email/@davidnelson',
        'hamster': 'https://t.me/hamster_kombat_boT/start?startapp=kentId1170015722',
        'agent': 'https://t.me/Agent301Bot/app?startapp=onetime1170015722',
        'freebitcoin': 'https://freebitco.in/?r=41656831',
        'sidefan': 'https://t.me/sidekick_fans_bot?start=1170015722',
        'wonton': 'https://t.me/WontonOrgBot/gameapp?startapp=referralCode=ZQU4FUL6',
        'coinpayu': 'https://www.coinpayu.com/?r=Davidnelson',
        'pocket': 'https://t.me/pocketfi_bot/Mining?startapp=1731371837',
        'sauce': 'https://sauces.world/@davidnelson',
        'cats': 'https://t.me/catsgang_bot/join?startapp=rLwerPH0TY6fTsuSKrikC',
        'satoshi': 'https://invite.satoshiapp.xyz/7j5qq',
        'truecoin': 'https://t.me/true_coin_bot?start=1170015722',
        'earnbitmoon': 'https://earnbitmoon.club/?ref=125258',
        'match-quest': 'https://t.me/MatchQuestBot/start?startapp=b0e03025b2ca2160315e2ebcacf4da52',
        'blum': 'https://t.me/BlumCryptoBot/app?startapp=ref_f1LDj4pzs5',
        'cex': 'https://t.me/cexio_tap_bot?start=1716270247236481',
        'simple': 'https://t.me/Simple_Tap_Bot/app?startapp=1716360435372',
        'athene': 'https://t.me/athene_official_bot/app?startapp=7575c96cae4c',
        'join-btn': 'https://t.me/softcoinupdates',
        'farm-btn': 'https://t.me/Tomarket_ai_bot/app?startapp=00001S56',
        'major': 'https://t.me/major/start?startapp=1170015722',
        'lost-dog': 'https://t.me/lost_dogs_bot/lodoapp?startapp=ref-u_1170015722__s_578244',
        'seed-btn': 'https://t.me/seed_coin_bot/app?startapp=1170015722',
        'sunwave': 'https://sunwavestoken.com/@davidnelson',
        'call': 'https://callfluent.io/@1170015722',
        'money-dog': 'https://t.me/money_dogs_bot/money_dogs?startapp=ou7n7ZHh',
        'mozoai': 'https://t.me/MozoAI_bot?start=1RE6BtMt',
        'nomiz': 'https://t.me/NomisAppBot/app?startapp=ref_Lw3iG-VUpB',
        'time-farm': 'https://t.me/TimeFarmCryptoBot?start=52dNTEOsZ344hdH9',
        'vertus': 'https://t.me/vertus_app_bot/app?startapp=1170015722',
        'hot': 'https://t.me/herewalletbot/app?startapp=1496880-village-27134',
        'daily-followw-btn': 'https://twitter.com/softcoin_pro',
        'daily-paws': 'https://t.me/PAWSOG_bot/PAWS?startapp=5rnJLvCG',
        'daily-doctorx': 'https://doctorx.meme/@davidnelson',
        'daily-facebook': 'https://www.facebook.com/profile.php?id=61567880244086',
        'daily-tiktok': 'https://www.tiktok.com/@softcoinpro',
        'daily-tweet': 'https://x.com/softcoin_pro/status/1850421442355032116?t=INRPpxVD9mS11zELR2Jhcg&s=19',
        'daily-pi': 'https://minepi.com/Davidnelson112',
        'daily-mrbitgenie': 'https://t.me/MrBitGenie_bot/tap?startapp=I45bd05ea',
        'daily-miniapps': 'https://t.me/miniappscenterbot?start=aW52PTY5Nzk2',
        'daily-coub': 'https://t.me/coub/app?startapp=coub__marker_18411358',
        'daily-faucetpay': 'https://faucetpay.io/?r=1413506',
        'daily-binance': 'https://www.binance.com/activity/referral-entry/CPA?ref=CPA_006WFACZKB',
        'daily-tondao': 'https://t.me/tonxdao_bot?start=dao_1170015722_1100147',
        'daily-notpixel': 'https://t.me/notpixel/app?startapp=f1170015722',
        'daily-goat': 'https://t.me/realgoats_bot/run?startapp=fe2dde2f-6ffa-4269-9de2-0a14e587a6ed',
        'daily-tada': 'https://t.me/TADA_Ride_Bot/join?startapp=ref_gCyQ-hIuxn',
        'daily-wallet': 'https://t.me/herewalletbot/grafun?startapp=D9EVkxPxnT0edsXAGPdA1odSz2n',
        'daily-litecoin': 'https://free-litecoin.com/login?referer=1639438',
        'daily-memefix': 'https://t.me/memefi_coin_bot/main?startapp=r_51d911bfe1',
        'daily-memelab': 'https://t.me/MemesLabBot?start=B77UIR',
        'daily-sealsend': 'https://sealsend.email/@davidnelson',
        'daily-hamster': 'https://t.me/hamster_kombat_boT/start?startapp=kentId1170015722',
        'daily-agent': 'https://t.me/Agent301Bot/app?startapp=onetime1170015722',
        'daily-freebitcoin': 'https://freebitco.in/?r=41656831',
        'daily-sidefan': 'https://t.me/sidekick_fans_bot?start=1170015722',
        'daily-wonton': 'https://t.me/WontonOrgBot/gameapp?startapp=referralCode=ZQU4FUL6',
        'daily-coinpayu': 'https://www.coinpayu.com/?r=Davidnelson',
        'daily-pocket': 'https://t.me/pocketfi_bot/Mining?startapp=1731371837',
        'daily-sauce': 'https://sauces.world/@davidnelson',
        'daily-cats': 'https://t.me/catsgang_bot/join?startapp=rLwerPH0TY6fTsuSKrikC',
        'daily-satoshi': 'https://invite.satoshiapp.xyz/7j5qq',
        'daily-truecoin': 'https://t.me/true_coin_bot?start=1170015722',
        'daily-earnbitmoon': 'https://earnbitmoon.club/?ref=125258',
        'daily-match-quest': 'https://t.me/MatchQuestBot/start?startapp=b0e03025b2ca2160315e2ebcacf4da52',
        'daily-blum': 'https://t.me/BlumCryptoBot/app?startapp=ref_f1LDj4pzs5',
        'daily-cex': 'https://t.me/cexio_tap_bot?start=1716270247236481',
        'daily-simple': 'https://t.me/Simple_Tap_Bot/app?startapp=1716360435372',
        'daily-athene': 'https://t.me/athene_official_bot/app?startapp=7575c96cae4c',
        'daily-join-btn': 'https://t.me/softcoinupdates',
        'daily-farm-btn': 'https://t.me/Tomarket_ai_bot/app?startapp=00001S56',
        'daily-major': 'https://t.me/major/start?startapp=1170015722',
        'daily-lost-dog': 'https://t.me/lost_dogs_bot/lodoapp?startapp=ref-u_1170015722__s_578244',
        'daily-seed-btn': 'https://t.me/seed_coin_bot/app?startapp=1170015722',
        'daily-sunwave': 'https://sunwavestoken.com/@davidnelson',
        'daily-call': 'https://callfluent.io/@1170015722',
        'daily-money-dog': 'https://t.me/money_dogs_bot/money_dogs?startapp=ou7n7ZHh',
        'daily-mozoai': 'https://t.me/MozoAI_bot?start=1RE6BtMt',
        'daily-nomiz': 'https://t.me/NomisAppBot/app?startapp=ref_Lw3iG-VUpB',
        'daily-time-farm': 'https://t.me/TimeFarmCryptoBot?start=52dNTEOsZ344hdH9',
        'daily-vertus': 'https://t.me/vertus_app_bot/app?startapp=1170015722',
        'daily-hot': 'https://t.me/herewalletbot/app?startapp=1496880-village-27134'
};
